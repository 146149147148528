<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            'account_number',
            { ...helpers.validIcons, key: 'is_principal' },
            helpers.activeBadge
          ]"
          :config="{
            url: 'admin/bank_accounts',
            route: '/admin/property/bank_accounts',
            name: 'bank_account',
            delete: false
          }"
        >
          <!-- TODO: On CRUDTable2 ? -->
          <template #delete="{item}">
            <CButton v-if="item.id && !item.is_principal" color="danger" @click="_deleteItem(item)">{{ $t('Delete') }}</CButton>
          </template>
        </ACRUDTable>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'BankAccounts',
  mixins: [crudMixin],
  methods: {
    _deleteItem(item) {
      if (this.$refs.crud_table) this.$refs.crud_table.deleteItem(item.id)
    }
  }
}
</script>
