var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          'account_number',
          Object.assign({}, _vm.helpers.validIcons, {key: 'is_principal'}),
          _vm.helpers.activeBadge
        ],"config":{
          url: 'admin/bank_accounts',
          route: '/admin/property/bank_accounts',
          name: 'bank_account',
          delete: false
        }},scopedSlots:_vm._u([{key:"delete",fn:function(ref){
        var item = ref.item;
return [(item.id && !item.is_principal)?_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm._deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('Delete')))]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }